@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;
@use "../../styles/partials/typography" as *;

.intro{
    padding: $padding-mobile-sides;
    display: flex;  
    flex-direction: column;
    gap: 1.5rem;
    @include tablet{
        padding: $padding-tablet-sides;
        padding-top: calc($padding-tablet-sides /3);
        padding-bottom: calc($padding-tablet-sides /2);
        gap: 2rem;

    }
    @include desktop{
        padding: $padding-desktop-sides;
        padding-top: calc($padding-desktop-sides /2);
        padding-bottom: calc($padding-desktop-sides /2);

    }
    
    &__header{
        text-align: center;
        @include tablet {
            font-size: 2.5rem;
        }
        @include desktop {
            font-size: 3rem;
        }
    }
    &__sub-text{
        text-align: center;
        @include tablet {
            font-size: 2rem;
            width: 80vw;
            min-width: 500px;
            margin: auto;
        }
        @include desktop {
            font-size: 1.5rem;
            width: 60vw;
            min-width: 500px;
        }
    }
    &__sub-section-a{
        display: flex;  
        flex-direction: column;
        gap: 1.25rem;
        @include desktop{
            flex-direction: row;
            justify-content: center;
        
        }
    }
    &__sub-section-ab{
        display: flex;  
        flex-direction: column;
        gap: 1.25rem;
        @include desktop{
            width: 49%;
        
        }
    }
    &__divider{
display: none;
        @include desktop{
            display: block;

        }
    }
}