@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;
@use "../../styles/partials/typography" as *;

@keyframes inflate {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}

// @keyframes deflate {
//   100% {
//     transform: scale(1.2);
//   }
//   0% {
//     transform: scale(1);
//   }
// }

.feat-project {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: $padding-mobile-sides;
  gap: $padding-mobile-sides;
  @include tablet {
    padding: $padding-tablet-sides;
    gap: $padding-tablet-sides;
    justify-content: center;
    align-items: start;
  }
  @include desktop {
    padding: $padding-desktop-sides;
    gap: $padding-desktop-sides;
  }
  &__header {
    text-align: center;
    @include tablet {
      font-size: 2.5rem;
    }
  }
  &__sub-cont {
    background-color: #f9f9f9;
    display: flex;
    flex-direction: column;
    border: 1px solid rgb(226, 226, 226);
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

    padding: $padding-mobile-sides;
    gap: $padding-mobile-sides;

    @include tablet {
      height: 100%;
    }
    @include desktop {
      padding: calc($padding-desktop-sides/2);
      gap: calc($padding-desktop-sides/2);
    flex-direction: row;
    }
  }
  &__img-name-cont {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    justify-content: start;
    @include desktop{
      width: 50%;
      justify-content: center;
    }
  }

  &__image {
    width: 100%;
    border-radius: 5px;
    padding: 1px;
    box-shadow: 12px 12px 12px rgba(0, 0, 0, 0.1) inset,
      -10px -10px 10px white inset;
    @include tablet {
      order: 2;
    }

  }
  &__name {
    font-size: 1.5rem;
    text-align: center;
    @include tablet {
      font-size: 2rem;
    }
    @include desktop {
      display: none;
    }
    &--desktop {
      display: none;
      @include desktop {
        display: block;
        order: 0;
      }
    }
  }
  // &__name-link {
  //   text-decoration: none;
  //   color: black;

  // }
  &__description {
    @include tablet {
      font-size: 1.5rem;
    }
    @include desktop {
      font-size: 1.6rem;
    }
  }
  &__des-cont {
    width: 100%;
    @include desktop {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      order: 1;
    }
  }
  &__tech-stack {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    list-style: none;
    line-height: 0.75rem;
    @include desktop {
      font-size: 1.6rem;
      line-height: 1rem;
    }
  }
  &__tech-stack-text {
    @include desktop {
      font-size: 1.6rem;
      line-height: 1rem;
    }
  }
  &__url {
    color: black;
    font-weight: bold;
    text-decoration: none;
  }
  &__btn {
    background-color: #f9f9f9;
    border: 1px solid black;
    border-radius: 5px;
    padding: 0.5rem;
    padding: 0.625rem;
    @include tablet {
      font-size: 1.5rem;
      padding: 20px;
    }


    &:hover {
      background-color: black;
      color: white;
      cursor: pointer;
      animation: inflate 0.3s ease-in-out forwards ;
      
    }
    // &:not(:hover){
    //   animation: deflate 0.3s ease-in-out backwards ;
    // }
  }
  &__btn-cont {
    display: flex;
    justify-content: center;
    text-decoration: none;
    margin-bottom: $padding-mobile-sides;
    @include tablet {
      margin-bottom: $padding-tablet-sides;
    }
    @include desktop {
      margin-bottom: $padding-desktop-sides;
    }
  }
}



