@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;
@use "../../styles/partials/typography" as *;

.skills {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  @include tablet {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  &__header {
    text-align: center;
    @include tablet {
      font-size: 2.5rem;
      // padding-bottom: 1rem;
    }
  }
  &__sub-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1rem;
    border-radius: 1rem;
    padding: 0.5rem;
    box-shadow: inset 0px 0px 4px 5px rgba(141, 141, 141, 0.1);
    @include tablet {
      margin: 1.75rem;
    }
    @include desktop {
      margin: 2rem 5rem 2rem 5rem;
    }
  }
  &__sub-header {
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 1.1rem;
    box-shadow: 0px 0px 2px 2.5px rgba(141, 141, 141, 0.051);
    padding: 0.25rem 0.5rem 0.25rem 0.5rem;
    border-radius: 0.5rem;
    @include tablet {
      font-size: 2.25rem;
      margin-top: 2rem;
      margin-bottom: 2rem;
    }
    @include desktop {
      font-size: 3rem;
    }
  }
  &__section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    @include tablet {
      justify-content: center;
      gap: 2rem;
      padding-bottom: 2rem;
    }
  }
  &__sub-sect {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 35vw;
    height: 45vw;
    margin: 0.2rem;
    padding: 0.5rem;
    border-radius: 1rem;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);

    @include tablet {
      font-size: 2rem;
      padding: 1.5rem;
      margin: 0rem;
      width: 20vw;
      height: 30vw;
      display: flex;
      justify-content: space-between;
    }
    @include desktop {
      height: 15vw;
      width: 10vw;
      border: 1px solid rgba(179, 179, 179, 0.308);

    }
  }
  &__img {
    height: 75%;
    margin: 1rem;

    @include tablet {
      height: 70%;
    }
    @include desktop {
      height: 65%;
    }
  }
}

@include desktop {
}
