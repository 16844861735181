@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;
@use "../../styles/partials/typography" as *;

.hero{
    color: white;
    background-color: black;
    padding: $padding-mobile-sides;
    margin: $padding-mobile-sides;
    border-radius: 10px;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: .5rem;
    @include tablet{
        padding: $padding-tablet-sides;
        margin: $padding-tablet-sides;
        font-size: 1.75rem;
        gap: 1.5rem;
    }
    @include desktop{
        padding: calc($padding-desktop-sides/2);
        margin: $padding-desktop-sides;
        margin-top: calc($padding-desktop-sides/2);
        margin-bottom: calc($padding-desktop-sides/2);
        font-size: 2.5rem;
        gap: 2rem;
        flex-direction: row;
        align-items: center;
    }
    &__image{
        width: 100%;
        object-fit: cover;
        object-position: center;
    }
    &__section{
 
        @include desktop{
            display: flex;
            flex-direction: column;
            gap: 1rem;
            width: 100%;
        }
    }
    &__traits{
        display: flex;
        justify-content: center;
        font-size: .5rem;
        gap: .5rem;
        padding-top: .5rem;
        align-items: center;

        @include tablet {
            font-size: 1rem;
            padding-top: .75rem;
        }
        @include desktop {
            padding-top: 1rem;

        }
    }
}